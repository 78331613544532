import React from 'react';
import OptionsData from '../../data/acfOptions.remotejson'

export default function SocialNav (props) {
  const renderSocialNav = items =>
    OptionsData.acf.social_media.map(item => {
      return (
        <li key={item.social_url}>
          <a
            className='link--social'
            aria-label={item.social_name}
            href={item.social_url}
            target='_blank'
            rel='noopener'
          >
            <span className={item.social_icon}></span>
          </a>
        </li>
      )
    })

  return (
    <React.Fragment>
      <nav
        className='nav-up'
        aria-label={` ${
          props.bottom ? 'Footer Social' : 'Mobile Footer Social'
        }`}
      >
        <ul
          className={`social ${
            props.bottom ? 'footer-bottom__social' : 'footer-top__social'
          }`}
        >
          {renderSocialNav()}
        </ul>
      </nav>
    </React.Fragment>
  )
}
