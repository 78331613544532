
import Head from "next/head";

// universal code for constant contact form

export default function SubscribeScript () {

    return (
    <Head>
        <script> var _ctct_m = "d79e3f19c52f3a504ea3cbeac050b23c"; </script>
        <script id="signupScript" src="//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js" async defer></script>
    </Head> 
    )
  }