import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import Head from 'next/head'
import { useInView } from 'react-intersection-observer'
import IndexPage from './IndexPage' //Customized header
import MainNavigation from './Navbar'
import HeroFullbleed from '../Hero/HeroFullbleed.js'
import HeroFullVideo from '../Hero/HeroFullVideo.js'

const Header = React.memo(props => {
  const [pos, setPos] = useState('top')
  useEffect(() => {
    document.addEventListener('scroll', e => {
      let scrolled = document.scrollingElement.scrollTop
      if (scrolled >= 80) {
        setPos('moved')
      } else {
        setPos('top')
      }
    })
  }, [])

  return (
    <React.Fragment>
      <IndexPage {...props} />
      <header className={`${props.left ? 'single-story' : ''}`}>
        <div>
          <MainNavigation position={pos == 'top' ? 'top' : 'moved'} />
        </div>
        {props.bgImage ? (
          props.bgVideo ? (
            <HeroFullVideo {...props} />
          ) : (
            <HeroFullbleed {...props} />
          )
        ) : (
          ''
        )}
      </header>
    </React.Fragment>
  )
})

export default Header
